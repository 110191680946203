import * as React from 'react';
import { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import './styles/tags.scss';
import PostList from '../components/PostList';

const About = ({data}) => {
    const metaData = data.site.siteMetadata;
    const mapTitle = data.allMarkdownRemark.edges.map( ({node}, index)=>{
    return(<li>{index} - {node.frontmatter.title} - {node.frontmatter.tags} - {node.frontmatter.category}</li>);
    })
    return(
        <Layout >
            <h1>Title:{data.site.siteMetadata.title}</h1>
            <h2>url : {metaData.siteUrl}</h2>
            <div >
                <p>{mapTitle}</p>
            </div>

        </Layout>
    );
}

export const pageQuery = graphql`
  query {
    site{
        siteMetadata{
            title
            siteUrl
        }
    }
    allMarkdownRemark{
        edges{
            node{
                frontmatter{
                    title
                    tags
                    category
                }
            }

        }
    }
  }
`;

export default About;
